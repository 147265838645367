import React from "react";
import { graphql } from "gatsby";
import { usePreview, makeSlices } from "@kogk/gatsby-theme-prismic";
import "@src/data/prismic-fragments/page";
import { NewsFootnote } from "@cmp/sections";
import { MainLayout, SEO } from "@cmp/site";
import { Container, Row, Col, FancyShow } from "@kogk/common";
import sliceMapping from "@src/data/prismic-slice-mapping/page-slices";
import { GlobalDataProvider } from "@src/data/global-data";

import { formatDateString } from "@src/utils";

const Slices = makeSlices(sliceMapping);

export default ({ data: staticData, location, shouldMove = true }) => {
  const data = usePreview(staticData);
  const language = data.prismicNewsPage.lang.substring(0, 2);
  const {
    page_title: { text: title },
    date,
    news_intro: { text: intro },
  } = data.prismicNewsPage.data;

  const altUrls = [
    ...data.prismicNewsPage.alternate_languages.map(({ document }) => document),
    { url: location.pathname, lang: language },
  ];

  const pathName = location && location.pathname ? location.pathname : "";
  const origin = typeof window !== "undefined" ? document.location.origin : "";
  const image = data.prismicNewsPage.data.body.find(
    (item) => item.slice_type === "image"
  )?.primary?.image?.url;

  const seoMapped = {
    "og:type": "website",
    "og:url": origin + pathName,
    "og:title": title,
    description: intro,
    "og:description": intro,
    "twitter:description": intro,
    "og:image": image,
    "og:image:secure": image,
    "twitter:image": image,
  };
  return (
    <GlobalDataProvider language={language} altUrls={altUrls}>
      <MainLayout altUrls={altUrls}>
        <SEO seo={seoMapped} />
        <article>
          <FancyShow shouldMove={shouldMove}>
            <Container>
              <Row>
                <Col col={{ lg: 8 }} offset={{ lg: 2 }}>
                  <p className="eyebrow mb-1">{formatDateString(date)}</p>
                  <h2 className="mb-1 pb-1">{title}</h2>
                  <p className="parag-big">{intro}</p>
                </Col>
              </Row>
            </Container>
          </FancyShow>
          <Slices body={data.prismicNewsPage.data.body} />
          <NewsFootnote />
        </article>
      </MainLayout>
    </GlobalDataProvider>
  );
};

export const query = graphql`
  query NewsPageQuery($prismicId: ID!) {
    prismicNewsPage(prismicId: { eq: $prismicId }) {
      ...prismicNewsPageFragmentFull
    }
  }
`;
