import {
  RichText,
  Hero,
  Embed,
  Feature,
  System,
  ContactUsForm,
  NewsOverview,
  LatestNews,
  LinkSection,
  RaftLinkSection,
  SmallImageSection,
  MediaFullWidth,
  Map,
  FrontpageImage,
  Video,
  Solupunktar,
  FeaturedFluid,
  QandA,
  ShortcutButtons,
  JumpLinkSection,
  JumpPointSection,
  SalesCardSection,
  ServiceStepsSection,
  ContactCardSection,
  IframeSection,
  ImageComparison
} from '@cmp/sections'
import { handleImgixUrl } from '@kogk/common'

export default {
  PrismicPageBodyContactCard: {
    component: ContactCardSection,
    dataToProps (data) {
      return {
        html: data.primary.rich_text.html,
        cardText: data.primary.card_text.html,
        linkText: data.primary.link_text,
        linkUrl: data.primary.link.url,
      }
    }
  },
  PrismicPageBodyServiceSteps: {
    component: ServiceStepsSection,
    dataToProps (data) {
      return {
        html: data.primary.rich_text.html,
        items: data.items,
      }
    }
  },
  PrismicPageBodySalesCard: {
    component: SalesCardSection,
    dataToProps (data) {
      return {
        html: data.primary.rich_text.html,
        items: data.items,
      }
    }
  },

  PrismicPageBodyJumpLink: {
    component: JumpPointSection,
    dataToProps (data) {
      return {
        slug: data.primary.jump_link_title.text,
      }
    }
  },

  PrismicPageBodyJumpPoints: {
    component: JumpLinkSection,
    dataToProps (data) {
      return {
        html: data.primary.rich_text.html,
        items: data.items,
      }
    }
  },

  PrismicAnimationPageBodyFrontpageAnimation: {
    component: FrontpageImage,
    dataToProps (data) {
      const {
        text: { html: text }
      } = data.primary

      return { text }
    }
  },

  PrismicPageBodyFaq: {
    component: QandA,
    dataToProps (data) {
      const heading = data.primary.heading.text
      const faqs = data.items.map(i => {
        const {
          question,
          category,
          answer: { html: answer },
        } = i
        return { category, answer, question }
      })
      .reduce((acc, value) => {
        if(!value.category) {
          return acc
        }
        if(!acc[value.category]) {
          acc[value.category] = []
        }
        acc[value.category].push({
          question: value.question,
          answer: value.answer,
        })
        return acc
      }, {})
      return { heading, faqs }
    }
  },
  PrismicPageBodyShortcutButtons: {
    component: ShortcutButtons,
    dataToProps (data) {
      return { 
        buttons: data.items.map(item => ({
          ...item,
          link: item.link.url,
          icon: item.link_icon
        }))
       }
    }
  },
  PrismicPageBodyFeaturedfluid: {
    component: FeaturedFluid,
    dataToProps (data) {
      const {
        primary: {
          text: { html },
          image,
          image_text: { text: caption }
        },
        slice_label: label
      } = data

      return {
        html,
        image,
        caption,
        label
      }
    }
  },
  PrismicPageBodySolupunktar: {
    component: Solupunktar,
    dataToProps (data) {
      const points = data.items.map(item => {
        const {
          icon: { url: icon },
          title: { text: title },
          text: { html: text }
        } = item
        return { icon, title, text }
      })
      return { points }
    }
  },
  PrismicNewsPageBodyVideo: {
    component: Video,
    dataToProps (data) {
      const {
        text: { text: text },
        video: { url: video },
        slice_label: sliceLabel
      } = data.primary

      return { video, text, sliceLabel }
    }
  },
  PrismicPageBodyFrontpageAnimation: {
    component: FrontpageImage,
    dataToProps (data) {
      const {
        text: { html: text }
      } = data.primary

      return { text }
    }
  },
  PrismicPageBodyMap: {
    component: Map,
    dataToProps (data) {
      const {
        url: mapImage,
        thumbnails: { mobile: { url: mobile }}
      } = data.primary.map_image

      return { mapImage, mobile }
    }
  },
  PrismicPageBodyContactSection: {
    component: ContactUsForm,
    dataToProps (data) {
      const {
        text: { text },
        title: { text: title },
        purchase_timeframe_options: purchaseOptions 
      } = data.primary

      return { text, title, purchaseOptions }
    }
  },
  PrismicPageBodyMediaSection: {
    component: MediaFullWidth,
    dataToProps (data) {
      const {
        media_item: { url: mediaItem },
        slice_label,
        image: { url: image }
      } = data.primary
      const sliceLabel = data.slice_label

      return { mediaItem, sliceLabel, image }
    }
  },
  PrismicPageBodyLogoSection: {
    component: SmallImageSection,
    dataToProps (data) {
      const {
        heading: { text: heading }
      } = data.primary
      const images = data.items ? data.items.map(item => {
        const {
          image: { url: image }
        } = item
        return { image }
      }) : null
      return { heading, images }
    }
  },
  PrismicPageBodyInformationalColumns: {
    component: RaftLinkSection,
    dataToProps (data) {
      const image = data.primary.image.url

      const imgix = handleImgixUrl(image)

      const rafts = data.items.map(item => {
        const {
          heading: { text: heading },
          link_title: { text: linkTitle },
          link: { url: link }
        } = item
        return { link, heading, linkTitle }
      })
      return { rafts, image: imgix.url() }
    }
  },
  PrismicPageBodyLinkSection: {
    component: LinkSection,
    dataToProps (data) {
      const links = data.items.map((item) => {
        const {
          link: { url: link},
          title: { text: title}
        } = item
        return { link, title }
      })

      return { links }
    }
  },
  PrismicPageBodyLatestNewsSection: {
    component: LatestNews,
    dataToProps (data) {
      const {
        title: { text: title },
        link: { url: link },
      } = data.primary

      return { title, link }
    }
  },
  PrismicPageBodyNewsOverview: {
    component: NewsOverview,
    dataToProps (data) {
      const {
        title: { text: title }
      } = data.primary

      return { title }
    }
  },
  PrismicNewsPageBodyContactUsFrom: {
    component: ContactUsForm,
    dataToProps (data) {
      const {
        text: { text },
        title: { text: title }
      } = data.primary

      return { text, title }
    }
  },
  PrismicPageBodyFeatured: {
    component: Feature,
    dataToProps (data) {
      const {
        primary: {
          text: { html },
          image,
          imge_caption: { text: caption }
        },
        slice_label: label
      } = data

      return {
        html,
        image,
        caption,
        label
      }
    }
  },
  PrismicNewsPageBodyFeatured: {
    component: Feature,
    dataToProps (data) {
      const {
        primary: {
          text: { html },
          image,
          imge_caption: { text: caption }
        },
        slice_label: label
      } = data

      return {
        html,
        image,
        caption
      }
    }
  },
  PrismicNewsPageBodyRichText: {
    component: RichText,
    dataToProps (data) {
      return {
        html: data.primary.rich_text.html,
      }
    }
  },
  PrismicNewsPageBodyImage: {
    component: Hero,
    dataToProps (data) {
      const {
        primary: {
          caption: { text: caption },
          image
        }
      } = data


      return {
        caption,
        imageAlt: image.alt,
        imageUrlDesktop: image?.url,
        imageUrlMobile: image.thumbnails.phone?.url
      }
    }
  },

  PrismicPageBodyRichText: {
    component: RichText,
    dataToProps (data) {
      return {
        html: data.primary.rich_text.html,
        textAlign: data.primary.text_align
      }
    }
  },

  PrismicPageBodyHeroimage: {
    component: Hero,
    dataToProps (data) {
      const {
        primary: {
          hero_caption: { html: caption },
          image
        }
      } = data

      return {
        caption,
        imageAlt: image.alt,
        imageUrlDesktop: image?.url,
        imageUrlMobile: image.thumbnails.phone?.url
      }
    }
  },

  PrismicPageBodyFeature: {
    component: Feature,
    dataToProps (data) {
      const {
        primary: {
          text: { html },
          image
        },
        slice_label: label
      } = data

      return {
        imageAlignment: label === 'image_on_right' ? 'right' : 'left',
        html,
        image
      }
    }
  },

  PrismicNewsPageBodyEmbed: {
    component: Embed,
    dataToProps (data) {
      return { html: data.primary.url.html }
    }
  },

  PrismicPageBodyEmbed: {
    component: Embed,
    dataToProps (data) {
      return { html: data.primary.url.html }
    }
  },

  PrismicPageBodyIframeEmbed: {
    component: IframeSection,
    dataToProps (data) {
      return { url: data.primary.iframe_url}
    }
  },

  PrismicPageBodyImageComparison: {
    component: ImageComparison,
    dataToProps (data) {
      return {
        aspect_ratio: data.primary.aspect_ratio,
        left_image: data.primary.left_image,
        right_image: data.primary.right_image,
      }
    }
  },


  PrismicPageBodySystem: {
    component: System,
    dataToProps (data) {
      return {
        programName: data.primary.program,
        options: data.items
          .filter(({ parameter, value }) => parameter && value !== null)
          .reduce((acc, item) => {
            acc[item.parameter] = item.value
            return acc
          }, {})
      }
    }
  }
}
