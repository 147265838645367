import React from 'react'
import { graphql } from 'gatsby'
import { usePreview, makeSlices } from '@kogk/gatsby-theme-prismic'
import '@src/data/prismic-fragments/page'
import { MainLayout, SEO } from '@cmp/site'
import sliceMapping from '@src/data/prismic-slice-mapping/page-slices'
import { GlobalDataProvider } from '@src/data/global-data'

const Slices = makeSlices(sliceMapping)

export default ({ data: staticData, location }) => {
  const data = usePreview(staticData)
  const pageTitle = data.prismicPage.data?.page_title.text
  const hasEuText = data.prismicPage.tags.filter((item) => {
    return item === 'WITH_EU_TEXT'
  })
  const language = data.prismicPage.lang.substring(0, 2)

  const altUrls = [
    ...data.prismicPage.alternate_languages.map(({ document }) => document),
    { url: location?.pathname, lang: language },
  ]
  const defaultKeywords = 'Carbfix, co2, emission, energy'
  const seoProperties = data.prismicPage.data.seo_properties.reduce(
    (result, item) => {
      if (item.type && item.value) {
        result[item.type] = item.value
      }
      return result
    },
    {}
  )

  const hasHeroImage = data.prismicPage.data.body[0]?.slice_type === 'heroimage'

  const pathName = location && location.pathname ? location.pathname : ''
  const origin = typeof window !== 'undefined' ? document.location.origin : ''
  const defaultDescription =
    'Carbfix provides a natural and permanent storage solution by turning CO2 into stone underground in less than two years.'
  const defaultImage =
    'https://images.prismic.io/carbfix/08d510a2-16aa-48f9-8047-366b71209b97_borkjarni_0.jpg?auto=format%2Ccompress&rect=350%2C0%2C700%2C1050&w=400&h=600&fit=max&q=50'
  const image = seoProperties?.image ?? defaultImage

  const seoMapped = {
    'og:type': 'website',
    'og:url': origin + pathName,
    'og:title': pageTitle,
    keywords: seoProperties.keywords ?? defaultKeywords,
    description: seoProperties?.description ?? defaultDescription,
    'og:description': seoProperties?.description ?? defaultDescription,
    'twitter:description': seoProperties?.description ?? defaultDescription,
    'og:image': image,
    'og:image:secure': image,
    'twitter:image': image,
  }

  return (
    <GlobalDataProvider language={language} altUrls={altUrls}>
      <MainLayout
        availableLanguages={language}
        altUrls={altUrls}
        hasEuText={hasEuText}
        hasHeroImage={hasHeroImage}
      >
        <SEO seo={seoMapped} language={language} />

        <article>
          <Slices body={data.prismicPage.data.body} />
        </article>
      </MainLayout>
    </GlobalDataProvider>
  )
}

export const query = graphql`
  query PageQuery($prismicId: ID!) {
    prismicPage(prismicId: { eq: $prismicId }) {
      ...prismicPageFragmentFull
    }
  }
`
