import React from 'react'
import { MainLayout as Main } from '@cmp/site'
import { GlobalDataProvider } from '@src/data/global-data'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import { Container, Row, Col, Div } from '@kogk/common'
import { variables } from './sass-variables'
import styles from './Styleguide.module.scss'

const loremIpsum = `
  Því enginn hafnar eða hatar eða flýr ánægju sem slíka af því að hún er
  ánægja, heldur af því að mikill sársauki fylgir henni fyrir þá sem kunna
  ekki að leita ánægjunnar skynsamlega; né heldur elskar nokkur maður
  sársauka sem slíkan af því að hann er sársauki, leitar hans og vill
  finna til hans, heldur af því að stundum vill svo til að með striti og
  sársauka getur hann haft einhverja mikla ánægju.
`

const avg = (acc, c, i, arr) => acc + c / arr.length

const colorLuminocity = rgbStr => {
  if (rgbStr === 'white') {
    return 1
  }

  const m = rgbStr.match(/^#([0-9a-f]{6})$/i)

  if (!m) {
    return 0.5
  }

  const matched = m[1]

  const lum = [
    parseInt(matched.substr(0, 2), 16),
    parseInt(matched.substr(2, 2), 16),
    parseInt(matched.substr(4, 2), 16)
  ].reduce(avg, 0)

  return lum / 255
}

export default () => {
  const { language } = useLanguage()

  return (
    <GlobalDataProvider language={language} altUrls={[]}>
      <Main>
        <Container className='pt-4 pb-4' fluid>
          <Row>
            <Col offset={{ md: 1 }} col={{ md: 10 }}>
              <Content />
            </Col>
          </Row>
        </Container>
      </Main>
    </GlobalDataProvider>
  )
}

const Content = () => (
  <>
    <h1>Styleguide</h1>

    <Rowp>
      <Col col={4}>
        <Lbl>Headings</Lbl>

        <Tag name='h1' />
        <Tag name='h2' />
        <Tag name='h3' />
        <Tag name='h4' />
        <Tag name='h5' />
        <Tag name='h6' />
        <a className='link--big d-block' href='/'>see all news</a>
        <a className='link--small d-block' href='/'>see all news</a>
        <a className='link--x-small' href='/'>see all news</a>
      </Col>

      <Col col={8}>
        <Lbl>Default text</Lbl>
        <div>{loremIpsum}</div>

        <Lbl>Paragraph text</Lbl>
        <p>{loremIpsum.substring(0, 140)}</p>
        <p>{loremIpsum.substring(200)}</p>
      </Col>
    </Rowp>

    <Rowp>
      <Col>
        <Lbl>Theme colors</Lbl>
        <ColorSlab colors={variables.color.theme} />
      </Col>

      <Col>
        <Lbl>Colors</Lbl>
        <ColorSlab colors={variables.color.colors} />
      </Col>

      <Col>
        <Lbl>Greys ("grays")</Lbl>
        <ColorSlab colors={variables.color.grays} />
      </Col>
    </Rowp>

    <Rowp>
      <Col>
        <Lbl>Spacing</Lbl>

        <Spacing className='pt-1 pb-1' />
        <Spacing className='pt-2 pb-2' />
        <Spacing className='pt-3 pb-3' />
        <Spacing className='pt-4 pb-4' />
        <Spacing className='pt-5 pb-5' />
        <Spacing className='pt-6 pb-6' />
      </Col>

      <Col>
        <Lbl>Buttons</Lbl>
        <div className='button'>send message</div>
      </Col>
    </Rowp>
  </>
)

const Rowp = props => <Row className='mt-3 mb-3' {...props} />

const ColorSlab = ({ colors }) => (
  <div>
    {colors.map(({ name, val }) => (
      <Div
        key={name}
        className={['p-2', styles.color]}
        style={{
          backgroundColor: val,
          color: colorLuminocity(val) < 0.9 ? 'white' : 'black',
          textAlign: 'center',
          fontWeight: 'bold'
        }}
      >
        {name}: {val}
      </Div>
    ))}
  </div>
)

const Tag = ({ name: Name }) => (
  <Name
    dangerouslySetInnerHTML={{
      __html: `This is ${Name}`
    }}
  />
)

const Spacing = ({ className, ...props }) => (
  <div
    className={className}
    {...props}
    style={{
      background: '#ddd',
      marginBottom: '20px'
    }}
  >
    {className}
  </div>
)

const Lbl = ({ children }) => (
  <div
    style={{
      border: '3px double #888',
      textAlign: 'center',
      margin: '40px 0',
      fontSize: '14px',
      lineHeight: '25px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      color: '#888'
    }}
  >
    {children}
  </div>
)
