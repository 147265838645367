import React from 'react'
import { graphql } from 'gatsby'
import { usePreview, makeSlices } from '@kogk/gatsby-theme-prismic'
import '@src/data/prismic-fragments/page'
import { AnimationLayout } from '@cmp/site'
import sliceMapping from '@src/data/prismic-slice-mapping/page-slices'
import { GlobalDataProvider } from '@src/data/global-data'

const Slices = makeSlices(sliceMapping)

export default ({ data: staticData, location }) => {
  const data = usePreview(staticData)
  const language = data.prismicAnimationPage.lang.substring(0, 2)

  return (
    <GlobalDataProvider language={language}>
        <AnimationLayout >
            <article>
            <Slices body={data.prismicAnimationPage.data.body} />
            </article>
        </AnimationLayout>
    </GlobalDataProvider>
  )
}

export const query = graphql`
  query AnimationPageQuery($prismicId: ID!) {
    prismicAnimationPage(prismicId: { eq: $prismicId }) {
      ...prismicAnimationPageFragmentFull
    }
  }
`;
